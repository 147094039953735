import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from "../../redux/slices/authSlice";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";


const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: theme.palette.bronzeShades.bronze1,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.bronzeShades.bronze4,
  },
  "& .MuiInputBase-input": {
    color: theme.palette.silverShades.light,
  },
  "& .MuiFilledInput-underline:before": {
    borderBottomColor: theme.palette.silverShades.light,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: theme.palette.silverShades.light,
  },
}));

function LoginForm({ open, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [credentials, setCredentials] = useState({ username: "", password: "" });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const { username, password } = credentials;
    // Mock authentication
    if (username === "admin") {
      //&& password === ''
      dispatch(login("admin"));
      handleClose();
      navigate("/admin");
    } else if (username === "customer") {
      //&& password === ''
      dispatch(login("customer"));
      handleClose();
      navigate("/customer");
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
          backdropFilter: "blur(10px)", // Blur effect
          boxShadow: `0 8px 32px 0 ${theme.palette.blackShades.dark}`,
          borderRadius: "16px",
          border: `1px solid ${theme.palette.blackShades.light}`,
          color: theme.palette.silverShades.light,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.palette.bronzeShades.bronze4,
          fontWeight: "bold",
        }}
      >
        Login
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 1,
          }}
        >
          <StyledTextField
            autoFocus
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            variant="filled"
            value={credentials.username}
            onChange={handleChange}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              borderRadius: "8px",
            }}
          />
          <StyledTextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="filled"
            value={credentials.password}
            onChange={handleChange}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              borderRadius: "8px",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          padding: theme.spacing(2),
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: theme.palette.bronzeShades.bronze3,
            color: theme.palette.silverShades.light,
            "&:hover": {
              backgroundColor: theme.palette.bronzeShades.bronze4,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: theme.palette.bronzeShades.bronze2,
            color: theme.palette.silverShades.light,
            "&:hover": {
              backgroundColor: theme.palette.bronzeShades.bronze1,
            },
          }}
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoginForm;
