// src/components/common/DropdownMenu.jsx
import React from "react";
import { Box, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const StyledDropdownMenu = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: 0,
  background: "rgba(0,0,0,0.5)",
  padding: "12px",
  boxShadow: `0px 10px 30px 0px rgba(82, 63, 105, 0.05)`,
  borderRadius: "8px",
  minWidth: "225px",
  zIndex: theme.zIndex.appBar + 1,
  border: "1px inset black;",
}));

const StyledList = styled(List)(({ theme }) => ({
  padding: 0,
  margin: 0,
}));

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  padding: theme.spacing(1.75, 2),
  transition: "all linear 0.3s",
  color: active ? theme.palette.bronzeShades.bronze1 : theme.palette.silverShades.light,
  backgroundColor: active ? theme.palette.blackShades.medium : "transparent",
  "&:hover": {
    backgroundColor: theme.palette.blackShades.black,
    color: theme.palette.bronzeShades.bronze1,
    cursor: "pointer",
  },
}));

const DropdownMenu = ({ items, onClose }) => {
  const location = useLocation();
  return (
    <StyledDropdownMenu>
      <StyledList>
        {items.map((item, index) => {
          const isActive = location.pathname === item.link;
          return (
            <StyledListItem button key={index} component={RouterLink} to={item.link} onClick={onClose} active={isActive ? 1 : 0}>
              {/* Render the icon if it exists */}
              {item.icon && <ListItemIcon sx={{ color: "inherit", minWidth: "40px" }}>{item.icon}</ListItemIcon>}
              <ListItemText primary={item.label} />
            </StyledListItem>
          );
        })}
      </StyledList>
    </StyledDropdownMenu>
  );
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      icon: PropTypes.element, // Optional icon property
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DropdownMenu;
