// CustomSelect.jsx

import React from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useScale } from "../../ScaleContext"; // Adjust the path as necessary

// Create a styled FormControl that accepts a 'scale' prop
const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "scale",
})(({ theme, scale }) => ({
  // Overall margin between form controls
  margin: scale > 1 ? theme.spacing(1, 0) : theme.spacing(2, 0),

  // InputLabel styles
  "& .MuiInputLabel-root": {
    color: theme.palette.bronzeShades.bronze1,
    fontSize: scale > 1 ? "0.8rem" : "1rem", // Adjust font size based on scale
    transform: scale > 1 ? "translate(5px, -15px) scale(1)" : undefined, // Adjust label position if needed
  },
  "& .MuiInputLabel-shrink": {
    transform: scale > 1 ? "translate(14px, -6px) scale(0.75)" : undefined,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.bronzeShades.bronze2,
  },

  // Select styles
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.blackShades.dark,
    padding: 0, // Remove default padding

    // Fieldset border colors
    "& fieldset": {
      borderColor: theme.palette.bronzeShades.bronze1,
      padding: "0 8px", // Reduce fieldset padding
    },
    "&:hover fieldset": {
      borderColor: theme.palette.bronzeShades.bronze2,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.bronzeShades.bronze2,
    },

    // Select input styles
    "& .MuiSelect-select": {
      padding: scale > 1 ? "4px 8px" : "8px 16px", // Adjust padding based on scale
      boxSizing: "border-box",
      fontSize: scale > 1 ? "0.8rem" : "1rem",
      minHeight: scale > 1 ? "24px" : "32px", // Adjust height if necessary
      color: theme.palette.silverShades.light,
    },
  },

  // Select icon styles
  "& .MuiSelect-icon": {
    color: theme.palette.silverShades.light,
    fontSize: scale > 1 ? "1rem" : "1.5rem", // Adjust icon size based on scale
  },

  // FormHelperText styles
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
    fontSize: scale > 1 ? "0.75rem" : "0.875rem", // Adjust helper text size based on scale
    marginTop: scale > 1 ? theme.spacing(0.5) : theme.spacing(1),
  },

  // Autofill styles (if applicable)
  "& .MuiSelect-select:-webkit-autofill": {
    backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    color: theme.palette.silverShades.light,
    "-webkit-text-fill-color": theme.palette.silverShades.light,
    transition: "background-color 5000s ease-in-out 0s",
  },
}));

const CustomSelect = ({ label, name, value, onChange, options, error, helperText, required = false }) => {
  const scale = useScale();

  return (
    <StyledFormControl
      variant="outlined"
      fullWidth
      required={required}
      error={!!error}
      margin="normal"
      scale={scale} // Pass the scale prop to StyledFormControl
    >
      <InputLabel>{label}</InputLabel>
      <Select label={label} name={name} value={value} onChange={onChange}>
        {options.map((option) => (
          <MenuItem
            key={option.value || option}
            value={option.value || option}
            style={{
              fontSize: scale > 1 ? "0.8rem" : "1rem", // Adjust font size based on scale
              padding: scale > 1 ? "4px 8px" : "8px 16px", // Adjust padding based on scale
            }}
          >
            {option.label || option}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

export default CustomSelect;
