// src/components/common/Carousel.jsx
import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import backgroundImage1 from "../../../assets/specials/sale1.jpg"; // Replace with your dummy images
import backgroundImage2 from "../../../assets/specials/sale2.jpg";
import backgroundImage3 from "../../../assets/specials/sale3.jpg";

const CarouselContainer = styled(Box)(({ theme }) => ({
  marginTop: "20%",
  width: "100%",
  height: "60vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  position: "relative",
}));
const SpecialsText = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.bronzeShades.bronze5,
  zIndex: 2,
}));

// Styled component for each slide
const Slide = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.2)",
  borderRadius: "8px",
  overflow: "hidden",

  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)", // For Safari support
  border: "1px outset rgba(255, 255, 255, 0.1)", // Optional: Adds a subtle border
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
}));

// Styled component for the image section
const ImageSection = styled(Box)(({ theme, image }) => ({
  width: "50%",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

// Styled component for the text section
const TextSection = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better text readability
}));

const dummySlides = [
  {
    image: backgroundImage1,
    title: "SINGLE PHASE INTEGRATED PREPAID METER",
    description: "This STS compliant integrated meter contains all the critical metering, token descryption, relay disconnection and reverse energy detection functionality.",
  },
  {
    image: backgroundImage2,
    title: "TRIPLE PHASE SMART METER",
    description: "Advanced features for comprehensive energy management across multiple phases with real-time monitoring and data analytics.",
  },
  {
    image: backgroundImage3,
    title: "DIGITAL ENERGY MONITOR",
    description: "Efficiently track and manage energy consumption with our state-of-the-art digital monitoring solutions.",
  },
];

const Carousel = () => {
  const theme = useTheme();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev + 1) % dummySlides.length);
    }, 2500); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const { image, title, description } = dummySlides[current];

  return (
    <CarouselContainer>
      {/* Fixed "SPECIALS" Text */}
      <SpecialsText variant="h2">SPECIALS</SpecialsText>

      {/* Current Slide */}
      <Slide key={current}>
        {/* Image Section */}
        <ImageSection image={image} />

        {/* Text Section */}
        <TextSection>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.silverShades.light,

              mb: theme.spacing(2),
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ color: theme.palette.silverShades.light, lineHeight: 1.6 }}>
            {description}
          </Typography>
        </TextSection>
      </Slide>
    </CarouselContainer>
  );
};

export default Carousel;
