// src/pages/Palette.jsx
import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function Palette() {
  //TEMPORARY COLOR PALETTE FOR DEVELOPMENT
  const theme = useTheme();
  const colors = [
    { name: "Black Dark", value: theme.palette.blackShades.dark },
    { name: "Black Medium", value: theme.palette.blackShades.medium },
    { name: "Black Light", value: theme.palette.blackShades.light },
    { name: "bronzeShades.bronze1", value: theme.palette.bronzeShades.bronze1 },
    { name: "bronzeShades.bronze2", value: theme.palette.bronzeShades.bronze2 },
    { name: "bronzeShades.bronze3", value: theme.palette.bronzeShades.bronze3 },
    { name: "bronzeShades.bronze4", value: theme.palette.bronzeShades.bronze4 },
    { name: "bronzeShades.bronze5", value: theme.palette.bronzeShades.bronze5 },
    { name: "bronzeShades.bronze6", value: theme.palette.bronzeShades.bronze6 },
    { name: "silverShades.light", value: theme.palette.silverShades.light },
    { name: "silverShades.dark", value: theme.palette.silverShades.dark },
  ];

  return (
    <div>
      {colors.map((color) => (
        <Box
          key={color.name}
          sx={{
            backgroundColor: color.value,
            height: 40,
            display: "flex",
            alignItems: "center",
            paddingLeft: 2,
          }}
        >
          <Typography variant="body1" sx={{ color: "#fff" }}>
            {color.name}: {color.value}
          </Typography>
        </Box>
      ))}
    </div>
  );
}

export default Palette;
