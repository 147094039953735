// src/App.jsx
import React, { useEffect, useState } from 'react';
import AppRoutes from './Routes';
import ErrorBoundary from './components/common/ErrorBoundary';
import { useSelector, useDispatch } from 'react-redux';
import LoginForm from './components/common/LoginForm';
import { closeLoginModal } from './redux/slices/uiSlice';


function App() {

  const [scale, setScale] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleResize = () => setScale(window.devicePixelRatio);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Set the zoom to 100% (overriding scaling)
    document.body.style.zoom = "100%";
  }, []);

  const open = useSelector((state) => state.ui.loginModalOpen); // Access loginModalOpen state
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeLoginModal()); // Dispatch action to close modal


  return (
    <ErrorBoundary>
      <AppRoutes />
      <LoginForm open={open} handleClose={handleClose} />
    </ErrorBoundary>
  );
}

export default App;
