// RegisterCompany.jsx

import React, { useState } from "react";
import { Box, Button, Stepper, Step, StepLabel, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { registerCompany } from "../../../redux/slices/authSlice";

import CustomCheckbox from "../../../components/fields/CustomCheckbox";
import CustomTextField from "../../../components/fields/CustomTextField";
import CustomSelect from "../../../components/fields/CustomSelect";
import CustomFileUpload from "../../../components/fields/CustomFileUpload";

const steps = ["Company Details", "Contact Details", "Representative Details", "Meter Installation Address", "Paperwork Checklist", "Acceptance of Terms", "Submit"];

const RegisterCompany = ({ onBackToUserTypeSelection }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const [formData, setFormData] = useState({
    company: {
      companyName: "",
      companyType: "",
      vatNumber: "",
    },
    contact: {
      name: "",
      surname: "",
      idNumber: "",
      email: "",
      hasRepresentative: false,
    },
    representative: {
      name: "",
      surname: "",
      idNumber: "",
      email: "",
    },
    address: {
      address1: "",
      address2: "",
      address3: "",
      province: "",
    },

    documents: {
      municipalBill: [],
      idPassport: [],
      representativeIdPassport: [],
      companyRegistration: [],
      bankLetter: [],
    },
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({});

  const handleNext = () => {
    if (validateStep(activeStep)) {
      if (activeStep === 1 && !formData.contact.hasRepresentative) {
        // If no representative, skip over the Representative Details step
        setActiveStep((prev) => prev + 2);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onBackToUserTypeSelection();
    } else if (activeStep === 3 && !formData.contact.hasRepresentative) {
      // If no representative, skip back over the Representative Details step
      setActiveStep((prev) => prev - 2);
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleChange = (e, section, field) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));

    setErrors((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: "",
      },
    }));
  };

  const handleFileChange = (e, docType) => {
    const files = e.target.files;
    setFormData((prev) => ({
      ...prev,
      documents: {
        ...prev.documents,
        [docType]: files,
      },
    }));

    setErrors((prev) => ({
      ...prev,
      documents: {
        ...prev.documents,
        [docType]: "",
      },
    }));
  };

  const validateStep = (step) => {
    let tempErrors = {};
    let key = "";
    switch (step) {
      case 0:
        tempErrors.company = {};
        key = "company";
        if (!formData.company.companyName.trim()) {
          tempErrors.company.companyName = "Company Name is required.";
        }
        if (!formData.company.companyType.trim()) {
          tempErrors.company.companyType = "Company Type is required.";
        }
        break;

      case 1:
        tempErrors.contact = {};
        key = "contact";
        if (!formData.contact.name.trim()) {
          tempErrors.contact.name = "Name is required.";
        }
        if (!formData.contact.surname.trim()) {
          tempErrors.contact.surname = "Surname is required.";
        }
        if (!formData.contact.idNumber.trim()) {
          tempErrors.contact.idNumber = "ID Number is required.";
        }
        if (!formData.contact.email.trim()) {
          tempErrors.contact.email = "Email is required.";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.contact.email)) {
          tempErrors.contact.email = "Invalid email address.";
        }
        break;

      case 2:
        if (formData.contact.hasRepresentative) {
          tempErrors.representative = {};
          key = "representative";
          if (!formData.representative.name.trim()) {
            tempErrors.representative.name = "Representative Name is required.";
          }
          if (!formData.representative.surname.trim()) {
            tempErrors.representative.surname = "Representative Surname is required.";
          }
          if (!formData.representative.idNumber.trim()) {
            tempErrors.representative.idNumber = "Representative ID Number is required.";
          }
          if (!formData.representative.email.trim()) {
            tempErrors.representative.email = "Representative Email is required.";
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.representative.email)) {
            tempErrors.representative.email = "Invalid email address.";
          }
        }
        break;

      case 3:
        tempErrors.address = {};
        key = "address";
        if (!formData.address.address1.trim()) {
          tempErrors.address.address1 = "Address Line 1 is required.";
        }
        if (!formData.address.province.trim()) {
          tempErrors.address.province = "Province is required.";
        }
        break;

      case 4:
        tempErrors.documents = {};
        key = "documents";
        if (formData.documents.municipalBill.length === 0) {
          tempErrors.documents.municipalBill = "Municipal/Eskom Bill is required.";
        }
        if (formData.documents.idPassport.length === 0) {
          tempErrors.documents.idPassport = "ID or Passport is required.";
        }
        if (formData.documents.companyRegistration.length === 0) {
          tempErrors.documents.companyRegistration = "Company Registration document is required.";
        }
        if (formData.documents.bankLetter.length === 0) {
          tempErrors.documents.bankLetter = "Bank confirmation letter is required.";
        }
        if (formData.contact.hasRepresentative && formData.documents.representativeIdPassport.length === 0) {
          tempErrors.documents.representativeIdPassport = "Representative ID or Passport is required.";
        }
        break;

      case 5:
        key = "termsAccepted";
        if (!formData.termsAccepted) {
          tempErrors.termsAccepted = "You must accept the Terms and Conditions.";
        }
        break;

      default:
        break;
    }
    setErrors(tempErrors);
    console.log({ step, formData, tempErrors });
    let noErrors = false;
    if (Object(tempErrors).hasOwnProperty(key)) {
      if (!Object.keys(tempErrors[key]).length) {
        noErrors = true;
      }
      if (tempErrors[key].hasOwnProperty("representative")) {
        if (!Object.keys(tempErrors[key]["representative"]).length) {
          noErrors = true;
        } else {
          noErrors = false;
        }
      }
    } else {
      noErrors = true;
    }
    return noErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep(activeStep)) {
      if (activeStep === steps.length - 1) {
        const submissionData = new FormData();

        submissionData.append("company.companyName", formData.company.companyName);
        submissionData.append("company.companyType", formData.company.companyType);
        submissionData.append("company.vatNumber", formData.company.vatNumber);

        submissionData.append("contact.name", formData.contact.name);
        submissionData.append("contact.surname", formData.contact.surname);
        submissionData.append("contact.idNumber", formData.contact.idNumber);
        submissionData.append("contact.email", formData.contact.email);
        submissionData.append("contact.hasRepresentative", formData.contact.hasRepresentative);

        if (formData.contact.hasRepresentative) {
          submissionData.append("representative.name", formData.representative.name);
          submissionData.append("representative.surname", formData.representative.surname);
          submissionData.append("representative.idNumber", formData.representative.idNumber);
          submissionData.append("representative.email", formData.representative.email);
        }

        submissionData.append("address.address1", formData.address.address1);
        submissionData.append("address.address2", formData.address.address2);
        submissionData.append("address.address3", formData.address.address3);
        submissionData.append("address.province", formData.address.province);

        // Documents
        Array.from(formData.documents.municipalBill).forEach((file) => {
          submissionData.append("documents.municipalBill", file);
        });
        Array.from(formData.documents.idPassport).forEach((file) => {
          submissionData.append("documents.idPassport", file);
        });
        if (formData.contact.hasRepresentative) {
          Array.from(formData.documents.representativeIdPassport).forEach((file) => {
            submissionData.append("documents.representativeIdPassport", file);
          });
        }
        Array.from(formData.documents.companyRegistration).forEach((file) => {
          submissionData.append("documents.companyRegistration", file);
        });
        Array.from(formData.documents.bankLetter).forEach((file) => {
          submissionData.append("documents.bankLetter", file);
        });

        submissionData.append("termsAccepted", formData.termsAccepted);

        dispatch(registerCompany(submissionData))
          .unwrap()
          .then((response) => {
            setActiveStep((prev) => prev + 1);
          })
          .catch((error) => {
            console.error("Registration failed:", error);
          });
      } else {
        handleNext();
      }
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Company Details
            </Typography>
            <CustomTextField label="Company Name" name="companyName" variant="outlined" fullWidth required value={formData.company.companyName} onChange={(e) => handleChange(e, "company", "companyName")} error={!!errors.company?.companyName} helperText={errors.company?.companyName} />
            <CustomSelect
              label="Company Type"
              name="companyType"
              value={formData.company.companyType}
              onChange={(e) => handleChange(e, "company", "companyType")}
              options={[
                { value: "", label: "Select Company Type" },
                { value: "CC", label: "Close Corporation (CC)" },
                { value: "Trust", label: "Trust" },
                { value: "Sole Proprietor", label: "Sole Proprietor" },
                {
                  value: "Sectional title complex",
                  label: "Sectional Title Complex",
                },
                { value: "Pty Ltd", label: "Proprietary Limited (Pty Ltd)" },
              ]}
              error={!!errors.company?.companyType}
              helperText={errors.company?.companyType}
              required
            />
            <CustomTextField label="VAT Number (if applicable)" name="vatNumber" variant="outlined" fullWidth value={formData.company.vatNumber} onChange={(e) => handleChange(e, "company", "vatNumber")} error={!!errors.company?.vatNumber} helperText={errors.company?.vatNumber} />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Contact Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextField label="Name" name="name" variant="outlined" fullWidth required value={formData.contact.name} onChange={(e) => handleChange(e, "contact", "name")} error={!!errors.contact?.name} helperText={errors.contact?.name} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField label="Surname" name="surname" variant="outlined" fullWidth required value={formData.contact.surname} onChange={(e) => handleChange(e, "contact", "surname")} error={!!errors.contact?.surname} helperText={errors.contact?.surname} />
              </Grid>
            </Grid>

            <CustomTextField label="ID Number" name="idNumber" variant="outlined" fullWidth required margin="normal" value={formData.contact.idNumber} onChange={(e) => handleChange(e, "contact", "idNumber")} error={!!errors.contact?.idNumber} helperText={errors.contact?.idNumber} />

            <CustomTextField label="Email" name="email" type="email" variant="outlined" fullWidth required margin="normal" value={formData.contact.email} onChange={(e) => handleChange(e, "contact", "email")} error={!!errors.contact?.email} helperText={errors.contact?.email} />

            <CustomCheckbox label="Do you require a representative?" checked={formData.contact.hasRepresentative} onChange={(e) => handleChange(e, "contact", "hasRepresentative")} />
          </Box>
        );
      case 2:
        if (formData.contact.hasRepresentative) {
          return (
            <Box>
              <Typography variant="h6" gutterBottom>
                Representative Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField label="Name" name="name" variant="outlined" fullWidth required value={formData.representative.name} onChange={(e) => handleChange(e, "representative", "name")} error={!!errors.representative?.name} helperText={errors.representative?.name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField label="Surname" name="surname" variant="outlined" fullWidth required value={formData.representative.surname} onChange={(e) => handleChange(e, "representative", "surname")} error={!!errors.representative?.surname} helperText={errors.representative?.surname} />
                </Grid>
              </Grid>

              <CustomTextField label="ID Number" name="idNumber" variant="outlined" fullWidth required margin="normal" value={formData.representative.idNumber} onChange={(e) => handleChange(e, "representative", "idNumber")} error={!!errors.representative?.idNumber} helperText={errors.representative?.idNumber} />

              <CustomTextField label="Email" name="email" type="email" variant="outlined" fullWidth required margin="normal" value={formData.representative.email} onChange={(e) => handleChange(e, "representative", "email")} error={!!errors.representative?.email} helperText={errors.representative?.email} />
            </Box>
          );
        } else {
          handleNext();
          return null;
        }
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Meter Installation Address
            </Typography>
            <CustomTextField label="Address Line 1" name="address1" variant="outlined" fullWidth required margin="normal" value={formData.address.address1} onChange={(e) => handleChange(e, "address", "address1")} error={!!errors.address?.address1} helperText={errors.address?.address1} />
            <CustomTextField label="Address Line 2" name="address2" variant="outlined" fullWidth margin="normal" value={formData.address.address2} onChange={(e) => handleChange(e, "address", "address2")} />
            <CustomTextField label="Address Line 3" name="address3" variant="outlined" fullWidth margin="normal" value={formData.address.address3} onChange={(e) => handleChange(e, "address", "address3")} />
            <CustomSelect
              label="Province"
              name="province"
              value={formData.address.province}
              onChange={(e) => handleChange(e, "address", "province")}
              options={[
                { value: "", label: "Select Province" },
                { value: "Gauteng", label: "Gauteng" },
                { value: "Western Cape", label: "Western Cape" },
                { value: "Eastern Cape", label: "Eastern Cape" },
                { value: "Northern Cape", label: "Northern Cape" },
                { value: "KwaZulu-Natal", label: "KwaZulu-Natal" },
                { value: "Free State", label: "Free State" },
                { value: "Limpopo", label: "Limpopo" },
                { value: "Mpumalanga", label: "Mpumalanga" },
                { value: "North West", label: "North West" },
              ]}
              error={!!errors.address?.province}
              helperText={errors.address?.province}
              required
            />
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Paperwork Checklist
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please upload the following documents in order to complete your registration.
            </Typography>

            <CustomFileUpload label="Copy of latest Municipal/Eskom Bill" id="municipal-bill-upload" onChange={(e) => handleFileChange(e, "municipalBill")} files={formData.documents.municipalBill} error={errors.documents?.municipalBill} />
            <CustomFileUpload label="Copy of ID or Passport" id="id-passport-upload" onChange={(e) => handleFileChange(e, "idPassport")} files={formData.documents.idPassport} error={errors.documents?.idPassport} />
            {formData.contact.hasRepresentative && <CustomFileUpload label="Copy of Representative ID or Passport" id="rep-id-passport-upload" onChange={(e) => handleFileChange(e, "representativeIdPassport")} files={formData.documents.representativeIdPassport} error={errors.documents?.representativeIdPassport} />}
            <CustomFileUpload label="Copy of Company Registration (if applicable)" id="company-registration-upload" onChange={(e) => handleFileChange(e, "companyRegistration")} files={formData.documents.companyRegistration} error={errors.documents?.companyRegistration} />
            <CustomFileUpload label="Bank Confirmation Letter (not older than 3 months)" id="bank-letter-upload" onChange={(e) => handleFileChange(e, "bankLetter")} files={formData.documents.bankLetter} error={errors.documents?.bankLetter} />
          </Box>
        );
      case 5:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Acceptance of Terms and Conditions
            </Typography>
            <CustomCheckbox
              label="I accept the Terms and Conditions"
              checked={formData.termsAccepted}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  termsAccepted: e.target.checked,
                }))
              }
            />
            {errors.termsAccepted && (
              <Typography variant="body2" color="error">
                {errors.termsAccepted}
              </Typography>
            )}
            <Typography variant="body2" sx={{ mt: 2 }}>
              By submitting a completed Meter Minds Registration Form (or by supplying registration details) you warrant that you have READ, UNDERSTOOD and ACCEPT the Meter Minds Service Agreement, Terms and Conditions in attached documents.
            </Typography>
          </Box>
        );
      case 6:
        return (
          <Box textAlign="center" p={4}>
            <Typography variant="h5" gutterBottom>
              Registration Successful!
            </Typography>
            <Typography variant="body1">Thank you for registering. You will receive a confirmation email shortly.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = "/")}
              sx={{
                mt: 3,
                backgroundColor: theme.palette.bronzeShades.bronze2,
                color: theme.palette.silverShades.light,
                "&:hover": {
                  backgroundColor: theme.palette.bronzeShades.bronze1,
                },
              }}
            >
              Go to Home
            </Button>
          </Box>
        );
      default:
        return "Unknown Step";
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
        background: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        boxShadow: `0 8px 32px 0 ${theme.palette.blackShades.dark}`,
        borderRadius: "16px",
        border: `1px solid ${theme.palette.blackShades.light}`,
        color: theme.palette.silverShades.light,
        p: { xs: 2, sm: 4 },
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 1 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                style: { color: theme.palette.bronzeShades.bronze2 },
              }}
              sx={{
                "& .MuiStepLabel-label": {
                  color: theme.palette.silverShades.dark,
                },
                "& .MuiStepLabel-label.Mui-active": {
                  color: theme.palette.silverShades.light,
                  fontWeight: "bold",
                },
                "& .MuiStepLabel-label.Mui-completed": {
                  color: theme.palette.bronzeShades.bronze4,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <Box>
          <Typography variant="h6" align="center">
            Thank you for registering!
          </Typography>
        </Box>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 4,
              }}
            >
              <Button
                onClick={handleBack}
                sx={{
                  backgroundColor: theme.palette.bronzeShades.bronze3,
                  color: theme.palette.silverShades.light,
                  "&:hover": {
                    backgroundColor: theme.palette.bronzeShades.bronze4,
                  },
                }}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: theme.palette.bronzeShades.bronze2,
                    color: theme.palette.silverShades.light,
                    "&:hover": {
                      backgroundColor: theme.palette.bronzeShades.bronze1,
                    },
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    backgroundColor: theme.palette.bronzeShades.bronze2,
                    color: theme.palette.silverShades.light,
                    "&:hover": {
                      backgroundColor: theme.palette.bronzeShades.bronze1,
                    },
                  }}
                >
                  Next
                </Button>
              )}
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};

export default RegisterCompany;
