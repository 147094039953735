import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery,  IconButton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate , useOutletContext } from 'react-router-dom';
import CustomPageTitle from "../../../../components/common/CustomTitle";
import CustomButton from "../../../../components/fields/CustomButton";
import ScrollProgressBar from "../../../../components/common/ScrollProgressBar";
import { Player } from "@lottiefiles/react-lottie-player";

import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BuildIcon from "@mui/icons-material/Build";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SavingsIcon from "@mui/icons-material/Savings";
import NatureIcon from "@mui/icons-material/Nature";
import GavelIcon from "@mui/icons-material/Gavel";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";



const benefits = [
  {
    title: "Enhanced Revenue Control",
    content:
      "Submetering allows for accurate billing based on individual unit consumption, ensuring that property managers receive appropriate compensation for utilities.",
    icon: <MonetizationOnIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/revenue_control.json"),
  },
  {
    title: "Reduced Disputes",
    content:
      "Clear, detailed usage data minimizes billing disputes with tenants, streamlining communication and reducing administrative burdens.",
    icon: <HandshakeIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/reduced_disputes.json"),
  },
  {
    title: "Operational Efficiency",
    content:
      "Automated data collection and reporting simplify management processes, allowing property managers to focus on other critical tasks.",
    icon: <BuildIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/operational_efficiency.json"),
  },
  {
    title: "Attractive Amenities",
    content:
      "For developers, incorporating submetering can enhance the appeal of a property, attracting tenants interested in transparency and efficiency.",
    icon: <EmojiEventsIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/attractive_amenities.json"),
  },
  {
    title: "Cost Savings",
    content:
      "By identifying and addressing inefficiencies, property managers can lower overall utility costs, improving the property’s bottom line.",
    icon: <SavingsIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/cost.json"),
  },
  {
    title: "Sustainability Initiatives",
    content:
      "Submetering supports sustainability goals by encouraging responsible resource usage among tenants, which can enhance the property's reputation.",
    icon: <NatureIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/sustainability_initiatives.json"),
  },
  {
    title: "Compliance and Reporting",
    content:
      "Submetering assists in meeting regulatory requirements and simplifies reporting, making it easier to maintain compliance.",
    icon: <GavelIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/reporting.json"),
  },
  {
    title: "Increased Tenant Satisfaction",
    content:
      "Transparent billing and accountability can lead to higher tenant satisfaction and retention rates.",
    icon: <SentimentSatisfiedIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/tenant_satisfication.json"),
  },
  {
    title: "Long-Term Value",
    content:
      "For developers, properties equipped with advanced utility management features, like submetering, can command higher resale values and attract investment.",
    icon: <TrendingUpIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/long_term_value.json"),
  },
];

const StyledBenefitCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  maxWidth: "75%",
  backgroundColor: alpha(theme.palette.common.white, 0.05),
  padding: theme.spacing(0, 2),
  margin: theme.spacing(1, "auto"), // Center align horizontally
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

function Developers() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
  const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();


  useEffect(() => {
    const currentSentinel = sentinelRef.current; // Capture the current ref
  
    if (!currentSentinel) return; // Exit if ref is not assigned
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );
  
    observer.observe(currentSentinel); // Use captured ref
  
    return () => {
      observer.unobserve(currentSentinel); // Use captured ref
      observer.disconnect(); // Clean up the observer
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(40, 40, 40, 1) 35%, rgba(60, 60, 60, 1) 100%)",
        color: theme.palette.silverShades.light,
        padding: theme.spacing(1, 4),
        minHeight: "100vh",
      }}
    >
      <div ref={sentinelRef} />

      <CustomPageTitle
        icon={PrecisionManufacturingIcon}
        tooltip="Empowering Developers"
        title="Benefits for Developers"
        sx={{
          textAlign: "center",
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed", // Changed to 'fixed'
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={() => navigate("/services/utility-sub-metering")}
          sx={{
            position: "absolute",
            left: "50px",
            color: isScrolled ? theme.palette.silverShades.dark : theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: isScrolled ? theme.palette.bronzeShades.bronze3 : theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        {/* Scroll Progress Bar */}
        <ScrollProgressBar contentRef={contentRef}/>

        {/* "Get in Touch" Button */}
        <Box
          sx={{
            position: "absolute",
            right: "40px",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {benefits.map((benefit, index) => (
        <StyledBenefitCard key={index}>
          {/* Icon and Title */}
          <Box
            sx={{
              flex: isSmallScreen ? "0" : "1",
              display: "flex",
              flexDirection: "column",
              alignItems: isSmallScreen ? "center" : "flex-start",
              marginBottom: isSmallScreen ? theme.spacing(1) : 0, // Reduced margin
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: theme.spacing(0.5), // Reduced margin
                color: theme.palette.bronzeShades.bronze1,
              }}
            >
              {benefit.icon}
              <Typography
                variant={isSmallScreen ? "h6" : "h5"} // Reduced font size
                sx={{
                  fontWeight: "bold",
                  marginLeft: theme.spacing(1),
                }}
              >
                {benefit.title}
              </Typography>
            </Box>
            <Typography variant="body2">{benefit.content}</Typography> {/* Smaller text */}
          </Box>

          {/* Animation */}
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: isSmallScreen ? 0 : theme.spacing(2), // Reduced margin
            }}
          >
            <Player
              autoplay
              loop
              src={benefit.animation}
              style={{
                height: isSmallScreen ? "80px" : "120px", // Reduced size
                width: isSmallScreen ? "80px" : "120px",
              }}
            />
          </Box>
        </StyledBenefitCard>
      ))}
    </Box>
  );
}

export default Developers;
