// CustomCheckbox.jsx
import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

// Define custom dark colors
const COLORS = {
  bgColor: "#2c2c2c", // Base dark color
  ltColor: "#1a1a1a", // Lighter dark color for gradient
  drColor: "#3a3a3a", // Darker dark color for gradient
  switchOnColor: "#b08d57", // Custom bronze color for "on" state
  switchOffColor: "#171616", // Custom bronze color for "on" state
};

// Styled Components
const CustomCheckboxContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: "4px", // Reduced space underneath
});

const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  margin: "0", // Removed margin to reduce space
});

const SwitchWrap = styled("div")({
  background: `linear-gradient(145deg, ${COLORS.ltColor}, ${COLORS.drColor})`,
  border: `3px solid ${COLORS.bgColor}`, // Optionally reduce border thickness
  margin: "5px", // Optionally reduce margin
  display: "grid",
  borderRadius: "20px", // Adjusted border radius
  padding: "2px", // Adjusted padding
});

const SwitchLabel = styled("label")({
  cursor: "pointer",
  display: "inline-block",
  width: "40px", // Reduced width
  height: "20px", // Reduced height
  borderRadius: "10px", // Half of the new height for rounding
  background: COLORS.bgColor,
  position: "relative",
  transition: "background 240ms",
});

const HiddenCheckbox = styled("input")({
  appearance: "none",
  width: 0,
  height: 0,
  opacity: 0,
  position: "absolute",
});

const Slider = styled("span")(({ checked }) => ({
  position: "absolute",
  top: "2px", // Adjusted for the new size
  left: checked ? "22px" : "2px", // Adjusted positions
  width: "16px", // Reduced width
  height: "16px", // Reduced height
  borderRadius: "50%",
  background: checked ? COLORS.switchOnColor : COLORS.switchOffColor,
  transition: "left 240ms cubic-bezier(.5,.01,.5,1), background 240ms",
}));

const LabelText = styled("span")({
  marginLeft: "6px", // Reduced space between switch and label
  color: "#ccc",
  fontSize: "0.85rem", // Slightly smaller font size
});

const CustomCheckbox = ({ label, checked, onChange }) => {
  return (
    <CustomCheckboxContainer>
      <Wrapper>
        <SwitchWrap>
          <SwitchLabel>
            <HiddenCheckbox
              type="checkbox"
              checked={checked}
              onChange={onChange}
            />
            <Slider checked={checked} />
          </SwitchLabel>
        </SwitchWrap>
        {label && <LabelText>{label}</LabelText>}
      </Wrapper>
    </CustomCheckboxContainer>
  );
};

// PropTypes for type checking
CustomCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomCheckbox;
