// src/pages/CustomerPage.jsx
import React from 'react';
import { Typography } from '@mui/material';

function CustomerPage() {
  return (
    <div>
      <Typography variant="h4">Welcome to the Customer Portal</Typography>
      <Typography variant="body1">
        {/* Add content specific to the customer portal */}
        Here you can manage your account, view orders, and more.
      </Typography>
    </div>
  );
}

export default CustomerPage;
