// src/components/GetInTouch.jsx

import React from "react";
import { Box, Typography,  Grid } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import CustomTextField from "../../../components/fields/CustomTextField";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import CustomButton from "../../../components/fields/CustomButton";
import backgroundImageForm from "../../../assets/images/lightbulbs1.jpg";
import GenericEnhancedTitle from "../../../components/common/PageTitle";


const GlassmorphismContainer = styled(Box)(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.25)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  border: "1px outset rgba(255, 255, 255, 0.1)",
  padding: theme.spacing(4),
  color: theme.palette.silverShades.light,
}));

const NeumorphicForm = styled("form")(({ theme }) => ({
  backgroundImage: `linear-gradient(rgba(25, 24, 24, 0.6), rgba(21, 20, 20, 0.6)), url(${backgroundImageForm})`,
  backdropFilter: "blur(5px)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderTop: `1px inset white`,
  color: theme.palette.silverShades.light,

  borderRadius: "15px",
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

// const CustomTextField = styled(TextField)(({ theme }) => ({
//   "& .MuiFilledInput-root": {
//     backgroundColor: theme.palette.blackShades.black,
//     color: theme.palette.silverShades.light,
//     borderRadius: "5px",
//   },
//   "& .MuiFilledInput-root:hover": {
//     backgroundColor: theme.palette.blackShades.dark,
//   },
//   "& .MuiFilledInput-underline:before": {
//     borderBottomColor: theme.palette.silverShades.light,
//   },
//   "& .MuiFilledInput-underline:after": {
//     borderBottomColor: theme.palette.silverShades.main,
//   },
//   "& .MuiInputLabel-root": {
//     color: theme.palette.silverShades.light,
//   },
//   "& .MuiInputLabel-root.Mui-focused": {
//     color: theme.palette.silverShades.main,
//   },
//   "& input:-webkit-autofill": {
//     // Updated selector
//     backgroundColor: "rgba(255, 255, 255, 0.1) !important",
//     color: theme.palette.silverShades.light,
//     "-webkit-text-fill-color": theme.palette.silverShades.light,
//     transition: "background-color 5000s ease-in-out 0s",
//   },
// }));

const GetInTouch = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgb(0, 0, 0)",
        backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(41, 41, 41, 1) 100%)",
      }}
    >
      <GenericEnhancedTitle icon={BoltOutlinedIcon} tooltip="Powered by Meter Minds" title="Get in touch" sx={{
    textAlign: "center",
    maxWidth:"400px"
  }}/>


      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: "4%",
          right: "12%",
          bottom: "10%",
          display: "flex",
          gap: theme.spacing(4),
        }}
      >



        {/* Left Column */}
        <GlassmorphismContainer sx={{ flex: 1 }}>
          <Typography variant="h4" gutterBottom>
            Get in Touch
          </Typography>
          <Box sx={{ mt: 2 }}>
            {["Competitive Channel Fees", "Swift Response Time", "Customized solutions"].map((text, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon sx={{ color: theme.palette.bronzeShades.bronze1, mr: 1 }} />
                <Typography variant="body1">{text}</Typography>
              </Box>
            ))}
          </Box>
        </GlassmorphismContainer>

        {/* Right Column */}
        <Box sx={{ flex: 2 }}>
          <NeumorphicForm>
            <Grid container spacing={2}>
              {/* Row 1: First Name and Surname */}
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="First Name" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Surame" variant="outlined" />
              </Grid>

              {/* Row 2: Email and Cell No */}
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Email" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Cell Nr" variant="outlined" />
              </Grid>

              {/* Row 3: Query */}
              <Grid item xs={12}>
                <CustomTextField fullWidth label="Query" variant="outlined" />
              </Grid>

              {/* Row 4: Submit Button */}
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CustomButton link="/" text="Submit" />
              </Grid>
            </Grid>
          </NeumorphicForm>
        </Box>
      </Box>
    </Box>
  );
};

export default GetInTouch;
