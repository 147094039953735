// CustomButton.jsx
import React from 'react';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const CustomButton = ({ link, text }) => {
  const theme = useTheme();

  return (
    <Button
      variant="text"
      component={RouterLink}
      to={link}
      onClick={(e) => e.stopPropagation()}
      sx={{
        // position: 'absolute',
        // bottom: theme.spacing(0),
        // left: '40%',
        // transform: 'translateX(-25%)',
        px: 4,
        py: theme.spacing(0.5),
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '8px',
        border: '1px solid white',
        backgroundColor: alpha(theme.palette.blackShades?.black || '#000', 0.05),
        backdropFilter: 'blur(6px)',
        color: theme.palette.silverShades?.light || '#ccc',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
        '&:hover': {
          backgroundColor: alpha(theme.palette.blackShades?.black || '#000', 0.5),
          color: theme.palette.common.white,
          boxShadow: '1px 1px 16px rgba(255, 255, 255, 1)',
        },
      }}
    >
      {text}
    </Button>
  );
};

// PropTypes for type checking
CustomButton.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CustomButton;
