// src/components/common/NavButton.jsx
import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const StyledNavButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.blackShades.medium,
  color: theme.palette.silverShades.light,
  boxShadow: `5px 5px 10px ${theme.palette.blackShades.dark}, -5px -5px 10px ${theme.palette.blackShades.light}`,
  borderRadius: '10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.blackShades.light,
    color: theme.palette.bronzeShades.bronze2,
    boxShadow: `3px 3px 6px ${theme.palette.blackShades.dark}, -3px -3px 6px ${theme.palette.blackShades.light}`,
  },
  '&.active': {
    backgroundColor: theme.palette.blackShades.dark,
    color: theme.palette.bronzeShades.bronze1,
    boxShadow: `2px 2px 4px ${theme.palette.blackShades.dark}, -2px -2px 4px ${theme.palette.blackShades.light}`,
  },
}));

function NavButton({ to, children, isActive, onClick }) {
  return (
    <StyledNavButton
      component={to ? RouterLink : 'button'}
      to={to}
      onClick={onClick}
      className={isActive ? 'active' : ''}
    >
      {children}
    </StyledNavButton>
  );
}

NavButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

NavButton.defaultProps = {
  to: null,
  isActive: false,
  onClick: () => {},
};

export default NavButton;
