// src/pages/RechargePage.jsx
import React from "react";
import { Box, Typography, TextField, Button, Paper } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
}));

const FormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: "400px",
  width: "100%",
  backgroundColor: theme.palette.blackShades.dark,
  color: theme.palette.silverShades.light,
  boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.1)`,
  borderRadius: "12px",
}));

const RechargeButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.bronzeShades.bronze1,
  color: theme.palette.blackShades.black,
  "&:hover": {
    backgroundColor: theme.palette.bronzeShades.bronze4,
  },
}));

const RechargePage = () => {
  const theme = useTheme();

  const handleRecharge = (e) => {
    e.preventDefault();
    // Placeholder for recharge functionality
    alert("Recharge functionality is not implemented yet.");
  };

  return (
    <StyledContainer>
      <FormPaper elevation={3}>
        <Typography variant="h5" gutterBottom>
          Buy Electricity
        </Typography>
        <form onSubmit={handleRecharge}>
          <TextField
            label="Meter ID"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              style: { color: theme.palette.silverShades.light },
            }}
            InputProps={{
              style: { color: theme.palette.silverShades.light },
            }}
          />
          <TextField
            label="Amount (USD)"
            variant="outlined"
            type="number"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              style: { color: theme.palette.silverShades.light },
            }}
            InputProps={{
              style: { color: theme.palette.silverShades.light },
            }}
          />
          <RechargeButton type="submit" variant="contained" fullWidth>
            Recharge
          </RechargeButton>
        </form>
      </FormPaper>
    </StyledContainer>
  );
};

export default RechargePage;
