// src/index.js
import React from "react";
import ReactDOM from "react-dom/client"; // Use 'react-dom/client' for React 18
import { Provider as ReduxProvider } from "react-redux";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import queryClient from "./queryClient";
import { GlobalStyles } from "@mui/material";
import App from "./App";
import ErrorBoundary from "./components/common/ErrorBoundary";
import "./App.css";
import { ScaleProvider } from "./ScaleContext";
import { createScaledTheme } from "./theme";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={createScaledTheme}>
        <GlobalStyles
    styles={{
      // Hide scrollbar for all elements
      '*': {
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // IE 10+
      },
      '*::-webkit-scrollbar': {
        display: 'none', // Chrome, Safari, Opera
      },
    }}
  />
        <BrowserRouter>
          <ScaleProvider>
            <App />
          </ScaleProvider>
        </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </ReduxProvider>
  </ErrorBoundary>
);
