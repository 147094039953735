import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import CustomPageTitle from "../../../../components/common/CustomTitle";
import CustomButton from "../../../../components/fields/CustomButton";
import ScrollProgressBar from "../../../../components/common/ScrollProgressBar";
import { Player } from "@lottiefiles/react-lottie-player";

import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SecurityIcon from "@mui/icons-material/Security";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HouseIcon from "@mui/icons-material/House";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const benefits = [
  {
    title: "Accurate Billing",
    content: "Submetering provides precise measurements of individual unit consumption, ensuring residents are billed accurately for their actual usage rather than relying on averaged estimates.",
    icon: <PrecisionManufacturingIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/revenue_control.json"),
  },
  {
    title: "Cost Savings",
    content: " By tracking consumption closely, complexes can identify inefficiencies and reduce overall utility costs. This can lead to lower monthly bills for residents.",
    icon: <AttachMoneyIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/cost.json"),
  },
  {
    title: "Encourages Conservation",
    content: "When residents see their individual usage, they are more likely to conserve resources, leading to overall reduced consumption and environmental impact.",
    icon: <SecurityIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/conservation.json"),
  },
  {
    title: "Simplified Management",
    content: "Automated data collection and reporting reduce administrative burdens for property managers, streamlining billing processes and minimizing disputes.",
    icon: <ManageAccountsIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/management.json"),
  },
  {
    title: "Dispute Resolution",
    content: "Clear, transparent data makes it easier to resolve billing disputes quickly and fairly, enhancing resident satisfaction.",
    icon: <AccountBalanceWalletIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/reduced_disputes.json"),
  },
  {
    title: "Increased Property Value",
    content: "Properties with efficient utility management and transparent billing practices can attract more tenants and potentially increase property values.",
    icon: <HouseIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/propertyvalue.json"),
  },
  {
    title: "Transparency",
    content: "Meter Minds realises that no two complexes are the same, our adaptabilty allows us to customise not only our solution offering to you but also provide you with tailor made reports based on your requirements.",
    icon: <VisibilityIcon fontSize="large" />,
    animation: require("../../../../assets/animations/UtilitySubMetering/transparency.json"),
  },
];

const StyledBenefitCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  maxWidth: "75%",
  backgroundColor: alpha(theme.palette.common.white, 0.05),
  padding: theme.spacing(0, 2),
  margin: theme.spacing(1, "auto"), // Center align horizontally
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

function Complexes() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
  const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();

  useEffect(() => {
    const currentSentinel = sentinelRef.current; // Capture the current ref

    if (!currentSentinel) return; // Exit if ref is not assigned

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );

    observer.observe(currentSentinel); // Use captured ref

    return () => {
      observer.unobserve(currentSentinel); // Use captured ref
      observer.disconnect(); // Clean up the observer
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(40, 40, 40, 1) 35%, rgba(60, 60, 60, 1) 100%)",
        color: theme.palette.silverShades.light,
        padding: theme.spacing(1, 4),
        minHeight: "100vh",
      }}
    >
      <div ref={sentinelRef} />

      <CustomPageTitle
        icon={PrecisionManufacturingIcon}
        tooltip="Empowering Complexes"
        title="Benefits for Complexes"
        sx={{
          textAlign: "center",
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed", // Changed to 'fixed'
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={() => navigate("/services/utility-sub-metering")}
          sx={{
            position: "absolute",
            left: "50px",
            color: isScrolled ? theme.palette.silverShades.dark : theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: isScrolled ? theme.palette.bronzeShades.bronze3 : theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        {/* Scroll Progress Bar */}
        <ScrollProgressBar contentRef={contentRef} />

        {/* "Get in Touch" Button */}
        <Box
          sx={{
            position: "absolute",
            right: "40px",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {benefits.map((benefit, index) => (
        <StyledBenefitCard key={index}>
          {/* Icon and Title */}
          <Box
            sx={{
              flex: isSmallScreen ? "0" : "1",
              display: "flex",
              flexDirection: "column",
              alignItems: isSmallScreen ? "center" : "flex-start",
              marginBottom: isSmallScreen ? theme.spacing(1) : 0, // Reduced margin
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: theme.spacing(0.5), // Reduced margin
                color: theme.palette.bronzeShades.bronze1,
              }}
            >
              {benefit.icon}
              <Typography
                variant={isSmallScreen ? "h6" : "h5"} // Reduced font size
                sx={{
                  fontWeight: "bold",
                  marginLeft: theme.spacing(1),
                }}
              >
                {benefit.title}
              </Typography>
            </Box>
            <Typography variant="body2">{benefit.content}</Typography> {/* Smaller text */}
          </Box>

          {/* Animation */}
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: isSmallScreen ? 0 : theme.spacing(2), // Reduced margin
            }}
          >
            <Player
              autoplay
              loop
              src={benefit.animation}
              style={{
                height: isSmallScreen ? "80px" : "120px", // Reduced size
                width: isSmallScreen ? "80px" : "120px",
              }}
            />
          </Box>
        </StyledBenefitCard>
      ))}
    </Box>
  );
}

export default Complexes;
