// src/Routes.jsx
import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import WebsiteLayout from "./components/layout/WebsiteLayout";
import WebsitePage from "./pages/website/website/WebsitePage";
import RechargePage from "./pages/website/recharge/RechargePage";
import RegisterPage from "./pages/website/register/RegisterPage";
import GetInTouch from "./pages/website/website/GetInTouch";
//services:
import UtilitySubMetering from "./pages/website/services/UtilitySubMetering/UtilitySubMetering";
import PropertyOwners from "./pages/website/services/UtilitySubMetering/PropertyOwners";
import Complexes from "./pages/website/services/UtilitySubMetering/Complexes";
import Developers from "./pages/website/services/UtilitySubMetering/Developers";

import VendingSolution from "./pages/website/services/VendingSolution/VendingSolution";
import BulkMetering from "./pages/website/services/BulkMetering/BulkMetering";
import Recharge from "./pages/website/services/Recharge/Recharge";

//products:
import Connectivity from "./pages/website/products/Connectivity";
import Suppliers from "./pages/website/products/Suppliers";
//debug
import Palette from "./pages/website/website/Palette";

import CustomerLayout from "./components/layout/CustomerLayout";
import CustomerPage from "./pages/customer/CustomerPage";

import AdminLayout from "./components/layout/AdminLayout";
import AdminPage from "./pages/admin/AdminPage";

import ProtectedRoute from "./components/common/ProtectedRoute";

import { useSelector } from "react-redux";

function AppRoutes() {
  const auth = useSelector((state) => state.auth);

  return (
    <Routes>
      {/* Public Routes - Website */}
      <Route element={<WebsiteLayout />}>
        <Route path="/palette" element={<Palette />} />
        <Route path="/" element={<WebsitePage />} />
        {/* <Route path="/about" element={<AboutPage />} /> */}
        <Route path="/recharge" element={<RechargePage />} />
        <Route path="/services/utility-sub-metering" element={<UtilitySubMetering />} />
        <Route path="/services/property-owners" element={<PropertyOwners />} />
        <Route path="/services/complexes" element={<Complexes />} />
        <Route path="/services/developers" element={<Developers />} />


        <Route path="/services/vending-solution" element={<VendingSolution />} />
        <Route path="/services/bulk-metering" element={<BulkMetering />} />
        <Route path="/services/recharge" element={<Recharge />} />

        <Route path="/products/suppliers" element={<Suppliers />} />
        <Route path="/products/connectivity" element={<Connectivity />} />
        <Route path="/contact-us" element={<GetInTouch />} />
        <Route path="/register" element={<RegisterPage />} />
      </Route>

      {/* Customer Routes */}
      <Route element={<ProtectedRoute allowedRoles={["customer"]} />}>
        <Route path="/customer/*" element={<CustomerLayout />}>
          <Route index element={<CustomerPage />} />
        </Route>
      </Route>

      {/* Admin Routes */}
      <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
        <Route path="/admin/*" element={<AdminLayout />}>
          <Route index element={<AdminPage />} />
        </Route>
      </Route>

      {/* Redirect after login */}
      <Route path="/login-success" element={auth.role === "admin" ? <Navigate to="/admin" /> : auth.role === "customer" ? <Navigate to="/customer" /> : <Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
