// ScaleContext.js
import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createScaledTheme } from './theme';

const ScaleContext = createContext(1);

export const ScaleProvider = ({ children }) => {
  const [scale, setScale] = useState(window.devicePixelRatio || 1);

  useEffect(() => {
    const handleResize = () => {
      const newScale = window.devicePixelRatio || 1;
      setScale(newScale);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const theme = useMemo(() => createScaledTheme(scale), [scale]);

  return (
    <ScaleContext.Provider value={scale}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ScaleContext.Provider>
  );
};

export const useScale = () => useContext(ScaleContext);
