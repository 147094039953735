import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { styled,  } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";

import CustomButton from "../../../../components/fields/CustomButton";
import ScrollProgressBar from "../../../../components/common/ScrollProgressBar";
import CustomPageTitle from "../../../../components/common/CustomTitle";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

const StyledSlide = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "calc(100vh - 300px)",
  // backgroundImage: `url(${carouselBackgroundImage})`,
  // backgroundSize: "cover",
  // backgroundPosition: "center",
  // backgroundRepeat: "no-repeat",
  backdropFilter: "blur(6px)",
  background: "transparent",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.silverShades.light,
  textAlign: "center",
  padding: theme.spacing(4),
  borderTop: `1px inset ${theme.palette.silverShades.light}`,
  borderBottomRightRadius: "40px",
}));

const popups = [
  {
    title: "Are you a Submetering Company?",
    content: "Are you a submetering company in search of an effective vending software solution? Look no further than the proprietary software developed by Meter Minds. Our innovative platform streamlines utility management, offering seamless integration with your existing systems and providing real-time data insights for efficient billing and reporting. With user-friendly features designed specifically for submetering applications, our proprietary software enhances tenant engagement through easy payment options and transparent usage tracking. Our robust support team ensures a smooth implementation process, allowing you to focus on growing your business. Choose Meter Minds to elevate your submetering operations and drive customer satisfaction!",
    image: require("../../../../assets/submetering/submetering.jpg"),
    animation: require("../../../../assets/animations/vendingSolution/vendingSolution1.json"),
  },
  {
    title: "Need More Robust Reporting?",
    content: "Need more robust reporting that your current provider is unable to give you? We provide tailor-made reporting at no additional costs (T's and C's apply).",
    image: require("../../../../assets/submetering/reporting.jpg"),
    animation: require("../../../../assets/animations/vendingSolution/vendingSolution2.json"),
  },
  {
    title: "Bring Your Meters to Our Platform",
    content: "For more information on how we can assist you in bringing all your existing and future meters on your own SGC over to our platform, contact one of our dedicated specialists today.",
    image: require("../../../../assets/submetering/platform.jpg"),
    animation: require("../../../../assets/animations/vendingSolution/vendingSolution3.json"),
  },
];

function VendingSolution() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
 // const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();

  useEffect(() => {
    const currentSentinel = sentinelRef.current; // Capture the current ref
  
    if (!currentSentinel) return; // Exit if ref is not assigned
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );
  
    observer.observe(currentSentinel); // Use captured ref
  
    return () => {
      observer.unobserve(currentSentinel); // Use captured ref
      observer.disconnect(); // Clean up the observer
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(40, 40, 40, 1) 35%, rgba(60, 60, 60, 1) 100%)",
        color: theme.palette.silverShades.light,
        padding: theme.spacing(1, 4),
        minHeight: "100vh",
      }}
    >
      <div ref={sentinelRef} />

      <CustomPageTitle
        icon={PrecisionManufacturingIcon}
        tooltip="Vending Solutions"
        title="Vending Solutions"
        sx={{
          textAlign: "center",
        }}
      />

      {/* <GenericEnhancedTitle icon={PrecisionManufacturingIcon} tooltip="Powered by Meter Minds" title="Recharge Your Account" /> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed", // Changed to 'fixed'
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        {/* Back Button */}
        {/* <IconButton
        onClick={() => navigate("/services/utility-sub-metering")}
        sx={{
          position: "absolute",
          left: "50px",
          color: isScrolled ? theme.palette.silverShades.dark : theme.palette.bronzeShades.bronze1,
          backgroundColor: "transparent",
          "&:hover": {
            color: isScrolled ? theme.palette.bronzeShades.bronze3 : theme.palette.bronzeShades.bronze5,
          },
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton> */}

        {/* Scroll Progress Bar */}
        <ScrollProgressBar contentRef={contentRef} />

        {/* "Get in Touch" Button */}
        <Box
          sx={{
            position: "absolute",
            right: "40px",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {popups.map((popup, index) => (
        <StyledSlide
          key={index}
          tabIndex={0}
          onClick={() => navigate(popup.link)}
          onKeyDown={(e) => {
            if (e.key === "Enter") navigate(popup.link);
          }}
        >
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            {/* Left Column */}
            <Box
              sx={{
                flex: 2,
                marginLeft: theme.spacing(8), // Add margin to the left
                paddingRight: theme.spacing(2),
              }}
            >
              <Typography
                variant={isSmallScreen ? "h5" : "h4"}
                sx={{
                  fontWeight: "bold",
                  textShadow: "1px 1px 2px black",
                  mb: 2,
                }}
              >
                {popup.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "100%",
                  textShadow: "1px 1px 2px black",
                }}
              >
                {popup.content}
              </Typography>
            </Box>
            {/* Right Column */}
            <Box
              sx={{
                flex: 1,
                marginRight: theme.spacing(12), // Add margin to the right
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: theme.spacing(2),
                background: `transparent`,
                backdropFilter: "blur(6px)",
              }}
            >
              <Player src={popup.animation} loop autoplay style={{ maxWidth: "100%", maxHeight: "100%" }} />
            </Box>
          </Box>
        </StyledSlide>
      ))}
    </Box>
  );
}

export default VendingSolution;
