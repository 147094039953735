// src/pages/website/Connectivity.jsx

import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import backgroundImage from "../../../assets/electricity/hero3.jpg";
import simControlLogo from "../../../assets/suppliers/simcontrol.png";
// import hexingLogo from "../../../assets/suppliers/hexing.jpg";

const NeumorphicCard = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.85)",
  color: theme.palette.silverShades.light,
  boxShadow: `2px 2px 2px ${theme.palette.blackShades.medium}, -2px -2px 2px ${theme.palette.blackShades.light}`,
  borderRadius: "15px",
  maxWidth: "25%",
  height: "300px",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const ConnectivityLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "auto",
  objectFit: "contain",
  marginBottom: theme.spacing(2),
}));

function Connectivity() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        position: "relative",
        backgroundColor: "rgb(0, 0, 0)", // Fallback background color
        backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(41, 41, 41, 1) 100%)",
        m: 0,
        p: 0,
      }}
    >
      {/* Overlay Content */}
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: "8%",
          transform: "translateY(-50%)",
          color: "#fff",
          textAlign: "left",
          maxWidth: "40%",
        }}
      >
        <Typography variant="h2" gutterBottom>
          Seamless Connectivity Solutions
        </Typography>
        <Typography variant="h5">Our connectivity services ensure your smart metering systems are always online and functioning optimally. With robust network infrastructure and cutting-edge technology, we provide reliable data transmission and real-time monitoring.</Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "65%", // Adjust as needed
          left: "8%",
          transform: "translateY(-50%)",
          display: "flex",
          gap: 3, // Space between cards
          mt: 2,
        }}
      >
        {/* Supplier 1: Landis */}
        <NeumorphicCard>
          <CardContent sx={{ textAlign: "center" }}>
            <ConnectivityLogo src={simControlLogo} alt="SimControl Logo" />
            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
              Our advanced wireless solutions provide extensive coverage and high-speed data transmission, ensuring your meters stay connected without interruption.
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
              GPRS based connection with leading connectivity coverage, ensuring reliable and efficient data transmission for your smart metering systems.
            </Typography>
          </CardContent>
        </NeumorphicCard>
      </Box>
    </Box>
  );
}

export default Connectivity;
