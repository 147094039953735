// src/pages/website/UtilitySubMetering.jsx

import React from "react";
import { Box, Typography, Divider, Card, CardContent, useTheme, useMediaQuery } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import hero1 from "../../../../assets/electricity/hero1.jpg";
import hero2 from "../../../../assets/bulbs/bulbs3.jpg";
import hero3 from "../../../../assets/electricity/hero3.jpg";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import GenericEnhancedTitle from "../../../../components/common/PageTitle";

// Define card data
const cardData = [
  {
    title: "Property Owners",
    image: hero1,
    link: "/services/property-owners",
    body: "Managing and recovering utility funds can be costly, labor-intensive, and challenging, particularly when tenants dispute charges or refuse payment. We simplify this process with our online system, providing you with remote access to usage data, transaction records, and detailed reports. Key reports are automatically emailed to you each month.",
  },
  {
    title: "For Complexes",
    image: hero2,
    link: "/services/complexes",
    body: "Implementing submetering allows a body corporate to enhance billing accuracy, ensuring residents are charged only for their actual usage. This fosters accountability and encourages conservation, leading to potential cost savings for both the body corporate and residents. With streamlined management and transparent data, disputes are minimized, enhancing resident satisfaction. Effectively minimizes the need for extensive adminastration and debt recovery.Overall, submetering promotes a sustainable and financially sound living environment, increasing property value and appeal.",
  },
  {
    title: "Property Managing Agents / Developer",
    image: hero3,
    link: "/services/developers",
    body: "Submetering provides property managing agents and developers with a range of benefits, including accurate revenue management through precise billing based on actual usage, which reduces disputes. It enhances operational efficiency with automated data collection, allowing managers to focus on other tasks. Ultimately, submetering enhances financial performance and long-term property value, making it a smart choice for effective property management.",
  },
];

// Neumorphic Box Shadow
const neumorphicShadow = (theme) => `
  2px 2px 4px ${alpha(theme.palette.blackShades.black, 0.6)},
  -2px -2px 4px ${alpha(theme.palette.blackShades.medium, 0.6)}
`;

// Hover Animation
const hoverAnimation = keyframes`
  from {
    transform: scale(1);
    box-shadow: 8px 8px 16px rgba(0,0,0,0.6),
                -8px -8px 16px rgba(255, 255, 255, 0.1);
  }
  to {
    transform: scale(0.95);
    box-shadow: 12px 12px 24px rgba(0,0,0,0.6),
                -12px -12px 24px rgba(255, 255, 255, 0.1);
  }
`;

// Styled Card Component
const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderRadius: "12px",

  background: `linear-gradient(145deg, rgba(25, 24, 24, 0.1), rgba(21, 20, 20, 0.2));`,
  boxShadow: neumorphicShadow(theme),

  border: `1px solid ${theme.palette.blackShades.dark}`,
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    animation: `${hoverAnimation} 0.3s forwards`,
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "400px",
}));

// Styled Image
const CardImage = styled("img")({
  width: "80%",
  height: "200px",
  objectFit: "cover",
  margin: "auto",
  marginTop: "10px",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "20px",
});

// Styled CardContent to align title
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

function UtilitySubMetering() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgb(0, 0, 0)", // Fallback background color
        backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(41, 41, 41, 1) 100%)",

        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <GenericEnhancedTitle icon={BoltOutlinedIcon} tooltip="Powered by Meter Minds" title="Utility Sub-Metering" />
      


      <Box
        sx={{
          marginTop: theme.spacing(3),
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(6),
          justifyContent: "center",
          width: isSmallScreen ? "90%" : "100%",
        }}
      >
        {cardData.map((card, index) => (
          <StyledCard
            key={index}
            onClick={() => navigate(card.link)}
            sx={{
              maxWidth: isSmallScreen ? "100%" : "400px",
              flex: "1 1 400px",
            }}
          >
            <CardImage src={card.image} alt={card.title} />
            <StyledCardContent>
              <Typography variant="h5" color={theme.palette.bronzeShades.bronze1} sx={{ textAlign: "center" }}>
                {card.title}
              </Typography>
              <Divider
                sx={{
                  backgroundColor: theme.palette.bronzeShades.bronze1,
                  marginY: 1,
                }}
              />
              <Typography variant="body1" color={theme.palette.silverShades.dark}>
                {card.body}
              </Typography>
            </StyledCardContent>
          </StyledCard>
        ))}
      </Box>
    </Box>
  );
}

export default UtilitySubMetering;
