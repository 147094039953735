import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: theme.palette.blackShades.medium,
  color: theme.palette.silverShades.dark,
  boxShadow: `5px 5px 10px ${theme.palette.blackShades.black},
              -5px -5px 10px ${theme.palette.blackShades.dark}`,
  borderRadius: "10px",
  marginLeft: theme.spacing(1),
  "&:hover": {
    backgroundColor: theme.palette.blackShades.dark,
    color: theme.palette.silverShades.light,
  },
  "&.active": {
    backgroundColor: theme.palette.blackShades.black,
    color: theme.palette.bronzeShades.bronze1,
    fontWeight: "bold",
  },
}));

const NavButton = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledButton ref={ref} {...props}>
      {children}
    </StyledButton>
  );
});

export default NavButton;
