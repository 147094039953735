// Footer.jsx

import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFooter = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "15%",
  minWidth: "150px",
  backgroundColor: theme.palette.blackShades.dark,
  color: theme.palette.bronzeShades.bronze1,
  padding: theme.spacing(0.5),
  borderTopRightRadius: "8px",
  boxShadow: "0px -2px 10px rgba(0, 0, 0, 1)",
  zIndex: 1000,
  transition: "all 0.3s ease-in-out",

  // Responsive adjustments
  [theme.breakpoints.down("sm")]: {
    width: "100%", // Full width on small screens
    borderRadius: "0",
    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.3)",
  },
}));

const Footer = () => {
  const theme = useTheme();

  return (
    <StyledFooter>
      <Typography variant="body2" align="center">
        &copy; {new Date().getFullYear()} Meter Minds All rights reserved.
      </Typography>
    </StyledFooter>
  );
};

export default Footer;
