// src/components/common/ProtectedRoute.jsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ allowedRoles }) {
  const auth = useSelector((state) => state.auth);

  if (!auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!allowedRoles.includes(auth.role)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
