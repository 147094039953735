// CustomFileUpload.jsx

import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useScale } from "../../ScaleContext"; // Adjust the path as necessary

// Styled UploadButton that accepts a 'scale' prop
const StyledUploadButton = styled("label", {
  shouldForwardProp: (prop) => prop !== "scale",
})(({ theme, scale }) => ({
  marginTop: scale > 1 ? theme.spacing(0.5) : theme.spacing(1),
  backgroundColor: theme.palette.bronzeShades.bronze1,
  color: theme.palette.silverShades.light,
  textTransform: "none",
  padding: scale > 1 ? theme.spacing(0.5, 1) : theme.spacing(1, 2),
  borderRadius: "4px",
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "background-color 0.3s, box-shadow 0.3s, color 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.bronzeShades.bronze2,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.3)",
    color: theme.palette.blackShades.dark,
  },
}));

// Styled Box that accepts a 'scale' prop
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "scale",
})(({ theme, scale }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: scale > 1 ? theme.spacing(1) : theme.spacing(1.5),
  background: "rgba(50, 50, 50, 0.8)",
  borderRadius: "12px",
  boxShadow: `
    4px 4px 16px rgba(0, 0, 0, 0.6),
    -4px -4px 16px rgba(255, 255, 255, 0.1)
  `,
  padding: scale > 1 ? theme.spacing(0.5, 0.75) : theme.spacing(1, 1.25),
}));

// Styled Typography for labels and file names
const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "scale" && prop !== "variant",
})(({ theme, scale, variant }) => ({
  ...(variant === "subtitle1" && {
    fontSize: scale > 1 ? "0.85rem" : "1rem",
    fontWeight: "bold",
  }),
  ...(variant === "body2" && {
    fontSize: scale > 1 ? "0.75rem" : "0.875rem",
    color: theme.palette.silverShades.light,
  }),
  ...(variant === "error" && {
    fontSize: scale > 1 ? "0.75rem" : "0.875rem",
    color: theme.palette.error.main,
  }),
}));

// Styled UploadFileIcon that accepts a 'scale' prop
const StyledUploadFileIcon = styled(UploadFileIcon, {
  shouldForwardProp: (prop) => prop !== "scale",
})(({ scale }) => ({
  marginRight: scale > 1 ? 4 : 8,
  fontSize: scale > 1 ? "1rem" : "1.5rem",
}));

const CustomFileUpload = ({ label, id, onChange, files, error }) => {
  const scale = useScale();

  return (
    <StyledBox scale={scale}>
      <Grid container alignItems="center" spacing={scale > 1 ? 1 : 2}>
        <Grid item xs={12} sm={6}>
          <StyledTypography variant="h5" scale={scale}>
            {label}
          </StyledTypography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <input
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            style={{ display: "none" }}
            id={id}
            multiple
            type="file"
            onChange={onChange}
          />
          <StyledUploadButton htmlFor={id} scale={scale}>
            <StyledUploadFileIcon scale={scale} />
            Upload Files
          </StyledUploadButton>
        </Grid>
        <Grid item xs={12} sm={3}>
          {files.length > 0 ? (
            <StyledTypography variant="body2" scale={scale}>
              {Array.from(files).map((file, index) => (
                <span key={index}>
                  {file.name}
                  {index < files.length - 1 ? ", " : ""}
                </span>
              ))}
            </StyledTypography>
          ) : error ? (
            <StyledTypography variant="error" scale={scale}>
              {error}
            </StyledTypography>
          ) : null}
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default CustomFileUpload;
