// src/theme/index.js
import { createTheme } from '@mui/material/styles';
import palette from './palette';

export const createScaledTheme = (scale) => {
  return createTheme({
    palette,
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
      h1: {
        fontSize: `${3 / scale}rem`,
        fontWeight: 500,
        // Add responsive sizes if needed
      },
      h2: {
        fontSize: `${2.75 / scale}rem`,
        fontWeight: 500,
      },
      h3: {
        fontSize: `${2.5 / scale}rem`,
        fontWeight: 500,
      },
      h4: {
        fontSize: `${1.5 / scale}rem`,
        fontWeight: 500,
      },
      h5: {
        fontSize: `${1.25 / scale}rem`,
        fontWeight: 500,
      },
      h6: {
        fontSize: `${1 / scale}rem`,
        fontWeight: 500,
      },
      body1: {
        fontSize: `${1.075 / scale}rem`,
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: `${0.85 / scale}rem`,
        fontWeight: 400,
        lineHeight: 1.43,
      },
      // You can add more custom variants if needed
      subtitle1: {
        fontSize: `${1 / scale}rem`,
        fontWeight: 400,
        lineHeight: 1.75,
      },
      subtitle2: {
        fontSize: `${0.875 / scale}rem`,
        fontWeight: 500,
        lineHeight: 1.57,
      },
      caption: {
        fontSize: `${0.75 / scale}rem`,
        fontWeight: 400,
        lineHeight: 1.66,
      },
      overline: {
        fontSize: `${0.625 / scale}rem`,
        fontWeight: 400,
        lineHeight: 2.66,
        textTransform: 'uppercase',
      },
    },
    spacing: 8,
    // Add other theme configurations
  });
};
