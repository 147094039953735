// src/components/GenericEnhancedTitle.jsx

import React from "react";
import { Paper, Typography, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  zIndex: 1100,
  backgroundColor: alpha(theme.palette.blackShades.black, 0.7),
  padding: theme.spacing(1, 8),
  borderRadius: "30px",
  backdropFilter: "blur(25px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  animation: `${fadeIn} 1.2s ease forwards`,
  boxShadow: `
    0px 20px 40px ${alpha(theme.palette.blackShades.black, 0.5)}
  `,
  border: `3px outset ${theme.palette.bronzeShades.bronze1}`,
  cursor: "none",
  maxWidth: "500px",
  minWidth:"500px",
  margin: "75px auto 75px auto",
}));

function GenericEnhancedTitle({ icon: Icon, tooltip: tooltipText, title }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledPaper elevation={10}>
      {Icon && (
        <Tooltip title={tooltipText} arrow>
          <Icon
            sx={{
              color: theme.palette.bronzeShades.bronze1,
              fontSize: isSmallScreen ? "1rem" : "2rem",
              marginRight: theme.spacing(3),
              animation: `pulse 2s infinite`,
              "@keyframes pulse": {
                "0%": { transform: "scale(1)" },
                "50%": { transform: "scale(1.1)" },
                "100%": { transform: "scale(1)" },
              },
            }}
          />
        </Tooltip>
      )}
      <Typography
        variant={isSmallScreen ? "h4" : "h3"}
        sx={{
          margin: 0, // Remove default margin
          padding: 0, // Remove default padding
          background: `linear-gradient(45deg, ${theme.palette.bronzeShades.bronze1}, ${theme.palette.bronzeShades.bronze2})`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
          transition: "transform 0.3s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        {title}
      </Typography>
    </StyledPaper>
  );
}

export default GenericEnhancedTitle;
