// src/pages/AdminPage.jsx
import React from 'react';
import { Typography } from '@mui/material';

function AdminPage() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Typography variant="body1">
        Welcome to the admin portal. Manage your application here.
      </Typography>
    </div>
  );
}

export default AdminPage;
