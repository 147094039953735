// src/components/PageArrows.jsx
import React from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useTheme, styled } from "@mui/material/styles";
import PropTypes from "prop-types";

// Styled container to hold both arrows
const ArrowsContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: "0px",
  top: "60px",
  height: "calc(100vh - 60px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: 1000, // Ensure arrows are above other elements
  pointerEvents: "none", // Allow clicks only on buttons
}));

// Styled IconButton to handle pointer events
const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.bronzeShades.bronze3 : theme.palette.bronzeShades.bronze1,
  pointerEvents: "auto", // Enable clicks
  transition: "color 0.3s",
  "&:hover": {
    color: disabled ? theme.palette.bronzeShades.bronze2 : theme.palette.bronzeShades.bronze5,
  },
}));

const PageArrows = ({ section, setSection, maxSection }) => {
  const theme = useTheme();

  // Determine if arrows should be disabled
  const isTop = section === 0;
  const isBottom = section === maxSection;

  // Handlers for arrow clicks
  const handleScrollUp = () => {
    if (!isTop) {
      setSection(section - 1);
    }
  };

  const handleScrollDown = () => {
    if (!isBottom) {
      setSection(section + 1);
    }
  };

  return (
    <ArrowsContainer>
      {/* Up Arrow */}
      <StyledIconButton onClick={handleScrollUp} disabled={isTop} aria-label="Scroll Up">
        <ArrowUpward fontSize="large" />
      </StyledIconButton>

      {/* Down Arrow */}
      <StyledIconButton onClick={handleScrollDown} disabled={isBottom} aria-label="Scroll Down">
        <ArrowDownward fontSize="large" />
      </StyledIconButton>
    </ArrowsContainer>
  );
};

// PropTypes for type checking
PageArrows.propTypes = {
  section: PropTypes.number.isRequired,
  setSection: PropTypes.func.isRequired,
  maxSection: PropTypes.number.isRequired,
};

export default PageArrows;
